var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Card, Table, TableBody, TableContainer } from '@mui/material';
import Scrollbar from '../../../components/scrollbar';
import { TableHeadCustom, TableNoData, TablePaginationCustom, useTable, } from '../../../components/table';
import { IStatus } from '../../../@types/status';
import LoadingTable from '../../../components/loadingTable/LoadingTable';
import { dispatch, useSelector } from '../../../redux/store';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { deleteOneBookStock, getAllBookStocks } from '../../../redux/slices/bookStocks/actions';
import BookStockTableRow from './BookStockTableRow';
import BookStockTableToolbar from './BookStockTableToolbar';
var TABLE_HEAD = [
    {
        id: 'book',
        label: 'book',
        align: 'left',
    },
    { id: 'image', label: 'Image', align: 'center' },
    {
        id: 'level',
        label: 'level',
        align: 'center',
    },
    {
        id: 'section',
        label: 'section',
        align: 'center',
    },
    {
        id: 'office',
        label: 'office',
        align: 'left',
    },
    {
        id: 'quantity',
        label: 'quantity',
        align: 'left',
    },
    // {
    //   id: 'price',
    //   label: 'price',
    //   align: 'left',
    // },
    // {
    //   id: 'description',
    //   label: 'description',
    //   align: 'left',
    // },
    { id: 'createdAt', label: 'created At', align: 'center' },
    { id: '__action', label: 'Actions', align: 'center' },
];
var BookStockTable = function () {
    var _a;
    var _b = useTable({ defaultOrderBy: 'createdAt', defaultOrder: 'desc' }), dense = _b.dense, page = _b.page, order = _b.order, orderBy = _b.orderBy, rowsPerPage = _b.rowsPerPage, setPage = _b.setPage, selected = _b.selected, onSort = _b.onSort, onChangeDense = _b.onChangeDense, onChangePage = _b.onChangePage, onChangeRowsPerPage = _b.onChangeRowsPerPage, onSelectRow = _b.onSelectRow;
    var enqueueSnackbar = useSnackbar().enqueueSnackbar;
    var navigate = useNavigate();
    var _c = useState(null), filterOffice = _c[0], setFilterOffice = _c[1];
    var _d = useState(null), filterBook = _d[0], setFilterBook = _d[1];
    // Books Table Data
    var _e = useState([]), tableData = _e[0], setTableData = _e[1];
    // Search
    var _f = useState(''), searchValue = _f[0], setSearchValue = _f[1];
    // Get Books
    useEffect(function () {
        dispatch(getAllBookStocks({
            page: page,
            limit: rowsPerPage,
            search: searchValue,
            filterOffice: filterOffice,
            filterBook: filterBook,
        }));
    }, [page, rowsPerPage, dispatch, searchValue, filterOffice, filterBook]);
    var _g = useSelector(function (store) { return store.bookStocks; }), bookStocks = _g.bookStocks, status = _g.status;
    var isNotFound = IStatus.SUCCEEDED && !(tableData === null || tableData === void 0 ? void 0 : tableData.length);
    useEffect(function () {
        setTableData(bookStocks.docs);
    }, [bookStocks]);
    var dataInPage = tableData === null || tableData === void 0 ? void 0 : tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    var handleDeleteRow = function (id) {
        dispatch(deleteOneBookStock(id))
            .unwrap()
            .then(function (res) { return enqueueSnackbar("".concat(res === null || res === void 0 ? void 0 : res.message)); })
            .catch(function (err) { return enqueueSnackbar("".concat(err === null || err === void 0 ? void 0 : err.message), { variant: 'error' }); });
        if (page > 0) {
            if (dataInPage.length < 2) {
                setPage(page - 1);
            }
        }
    };
    var handleEditRow = function (row) {
        navigate("".concat(PATH_DASHBOARD.books.bookStocks.edit(row._id)), { state: { bookStock: row } });
    };
    var handleViewRow = function (row) {
        navigate("".concat(PATH_DASHBOARD.books.bookStocks.view(row._id)), { state: { bookStock: row } });
    };
    var handleResetFilter = function () {
        setPage(0);
        setFilterOffice(null);
        setFilterBook(null);
    };
    // const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    //   setPage(0);
    //   setSearchValue(event.target.value);
    // };
    var denseHeight = dense ? 52 : 72;
    var isFiltered = !!filterOffice || !!filterBook;
    return (_jsxs(Card, { children: [_jsxs(TableContainer, __assign({ sx: { position: 'relative', overflow: 'unset' } }, { children: [_jsx(BookStockTableToolbar, { filterOffice: filterOffice, setFilterOffice: setFilterOffice, filterBook: filterBook, setFilterBook: setFilterBook, onResetFilter: handleResetFilter, isFiltered: isFiltered }), _jsx(Scrollbar, __assign({ sx: { overflow: 'auto', maxHeight: 700 } }, { children: _jsxs(Table, __assign({ stickyHeader: true, "aria-label": "sticky table", size: dense ? 'small' : 'medium', sx: { minWidth: 800 } }, { children: [_jsx(TableHeadCustom, { order: order, orderBy: orderBy, headLabel: TABLE_HEAD, rowCount: 6, numSelected: selected.length, onSort: onSort }), _jsxs(TableBody, { children: [status === IStatus.LOADING ? (_jsx(LoadingTable, { height: denseHeight, fields: TABLE_HEAD.length, rowsPerPage: rowsPerPage })) : (_jsx(_Fragment, { children: tableData === null || tableData === void 0 ? void 0 : tableData.map(function (row) { return (_jsx(BookStockTableRow, { row: row, selected: selected.includes(row._id), onSelectRow: function () { return onSelectRow(row._id); }, onDeleteRow: function () {
                                                    handleDeleteRow(row._id);
                                                }, onEditRow: function () {
                                                    handleEditRow(row);
                                                }, onViewRow: function () {
                                                    handleViewRow(row);
                                                } }, row._id)); }) })), status === IStatus.SUCCEEDED && (tableData === null || tableData === void 0 ? void 0 : tableData.length) === 0 && (_jsx(TableNoData, { isNotFound: isNotFound }))] })] })) }))] })), _jsx(TablePaginationCustom, { count: ((_a = bookStocks === null || bookStocks === void 0 ? void 0 : bookStocks.meta) === null || _a === void 0 ? void 0 : _a.totalDocs) || 0, page: page, rowsPerPage: rowsPerPage, onPageChange: onChangePage, onRowsPerPageChange: onChangeRowsPerPage, dense: dense, onChangeDense: onChangeDense })] }));
};
export default BookStockTable;
